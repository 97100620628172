import { Injectable } from '@angular/core';
import { LoginUser } from 'src/app/_models/loginuser';
import { CardData, CardDataMK } from 'src/app/_models/card-data';
import { ApiService } from './api-service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UtilityService } from './utility.service';
import { CheckoutDialogComponent } from '../shared/checkout-dialog/checkout-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { Router } from '@angular/router';
import { CartService } from './cart.service';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {
  userSubscription: Subscription;
  user: LoginUser;
  constructor(private apiService: ApiService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private utilityService: UtilityService,
    private router: Router,
    private cartService: CartService,
    private authService: AuthenticationService) {
    this.userSubscription = this.authService.currentUser.subscribe(user => {
      this.user = user;
    });
  }
  mapCardDataToLoginUser(cd: CardData, u: LoginUser): CardData {
    cd.name = u.firstName + ' ' + u.lastName,
      cd.firstName = u.firstName;
    cd.lastName = u.lastName;
    cd.address = u.address;
    cd.city = u.city;
    cd.state = u.state;
    cd.zip = u.zip;
    cd.email = u.email;
    cd.billingAddress = u.address;
    cd.billingCity = u.city;
    cd.billingState = u.state;
    cd.billingZip = u.zip;
    cd.lsFirstName = u.lsFirstName;
    cd.lsLastName = u.lsLastName;
    cd.lsAddress = u.lsAddress;
    cd.lsAddress1 = u.lsAddress1;
    cd.lsCity = u.lsCity;
    cd.lsState = u.lsState;
    cd.lsZip = u.lsZip;
    cd.lsEmail = u.lsEmail;
    cd.lsPhone = u.lsPhone;
    cd.lsCountry = u.lsCountry;

    return cd;
  }

  mapCardDataToLastShipped(cdmk: CardDataMK, u: LoginUser): CardDataMK {
    //const param = { UserID: this.user.userID };
    const param = { UserID: u.userID };
    this.apiService.get('Checkout/GetLastShipped', param).subscribe((retval: any) => {
      if (retval) {
        //cd.name = retval.firstName + ' ' + retval.lastName;
        cdmk.firstName = retval.firstName;
        cdmk.lastName = retval.lastName;
        cdmk.address = retval.address;
        cdmk.address1 = retval.address;
        cdmk.city = retval.city;
        cdmk.state = retval.state;
        cdmk.zip = retval.zip;
        cdmk.country = retval.country;
        cdmk.email = retval.email;
        cdmk.phone = retval.phone;
        //cd.billingAddress = retval.address;
        //cd.billingCity = retval.city;
        //cd.billingState = retval.state;
        //cd.billingZip = retval.zip;
      }
    });
    return cdmk;
  }

  checkout() {
    //this.openCheckoutDialog();
    this.openCheckoutDialogMK();
  }

  public openCheckoutDialogMK() {
    let cardDataMK = new CardDataMK();
    cardDataMK = this.mapCardDataToLastShipped(cardDataMK, this.user);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.hasBackdrop = true;
    dialogConfig.data = { data: cardDataMK, user: this.user, };
    const dialogRef = this.dialog.open(CheckoutDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(_retval => {
    });
  }

  public openCheckoutDialog() {
    let cardData = new CardData();
    cardData = this.mapCardDataToLoginUser(
      cardData, this.user
    );
    const dialogConfig = new MatDialogConfig();
    dialogConfig.hasBackdrop = true;
    dialogConfig.data = { data: cardData, user: this.user, };
    const dialogRef = this.dialog.open(CheckoutDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(_retval => {
      // if (product) {
      // this.router.navigate(['/products', _product.id, _product.name]);
      // }
    });
  }

  completeCheckout(cd: CardData) {
    //  http://192.168.91.213/api/Checkout/CompleteCheckout
    this.apiService.post('Checkout/CompleteCheckout', cd).subscribe(retval => {
      this.utilityService.alertMK('Checkout Status: ' + retval.toString());
      this.cartService.getShopCart();
      this.router.navigate(['/']);
    },
      error => {
        let msg = 'Sorry, there has been a problem checking out. Please contact the help desk.';
        if (error.Message) {
          msg = error.Message;
        }
        this.utilityService.alertMK(msg);
      });
  }

  completeCheckoutMK(cdmk: CardDataMK) {
    this.apiService.post('Checkout/CompleteCheckout', cdmk).subscribe(retval => {
      this.utilityService.alertMK('Checkout Status: ' + retval.toString());
      this.cartService.getShopCart();
      ////jno add to reload balance - want reload from server
      //window.location.reload();
      this.router.navigate(['/']);
    },
      error => {
        let msg = 'Sorry, there has been a problem checking out. Please contact the help desk.';
        if (error.Message) {
          msg = error.Message;
        }
        this.utilityService.alertMK(msg);
      });
  }
}
