export class User {
  constructor(
    public UserID: string = '',
    public Name: string = '',
    public LastName: string = '',
    public Username: string = '',
    public Address: string = '',
    public City: string = '',
    public State: string = '',
    public Zip: string = '',
    public Phone: string = '',
    public Email: string = '',
    public Registered: string = '',
    public GroupID = 0,
    public CUSTNMBR: string = '',
    public Currency: string = '',
    public Balance: string = '',
    public Cashup: string = '',
    public PermissionLevel: string = '',
    public Password: string = '',
    public UserImage: string = ''
  ) {}
}
export class UserTest {
  constructor(
    public UserID: string = '',
    public Name: string = 'Tommy',
    public LastName: string = 'Gunn',
    public Username: string = 'tgunn',
    public Address: string = '1 Wicket Street',
    public City: string = 'Las Vegas',
    public State: string = 'NV',
    public Zip: string = '12345',
    public Phone: string = '212-678-1234',
    public Email: string = 'tgunn@hotmail.com',
    public Registered: string = '0',
    public GroupID = 100240,
    public CUSTNMBR: string = '',
    public Currency: string = '',
    public Balance: string = '',
    public Cashup: string = '',
    public PermissionLevel: string = '',
    public Password: string = '',
    public UserImage: string = ''
  ) {}
}
